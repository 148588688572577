<template>
  <b-modal
    id="reorder-widgets-modal"
    v-model="modalShow"
    size="md"
    centered
    no-stacking
    hide-footer
    hide-header-close
    no-close-on-backdrop
    no-close-on-esc
    body-class="pt-0"
    header-class="pb-0 border-bottom-0"
    aria-labelledby="Rorder widgets modal"
    :static="true"
  >
    <template #modal-header>
      <div class="d-flex w-100 align-items-center justify-content-end">
        <div class="modal-right-items">
          <a href="javascript:void(0)" id="reorder-widgets-close-btn" @click="$emit(`closeReorderWidgetsModal`)"
            ><img src="@/assets/img/close.png" alt="close"
          /></a>
        </div>
      </div>
    </template>
    <div class="d-block px-3">
      <h3 class="font-21 theme-font-medium" id="reorder-widet-title">Reorder widgets</h3>
      <div id="widget-instructions" class="sr-only">Press spacebar to grab and set the widget. Use arrow keys to move the widget.</div>
      <div class="custom-scroll h-500 px-3 py-1">
        <draggable v-model="widgetsToReorder" @start="onStartWidgetDrag" @end="onStopWidgetDrag">
          <transition-group>
            <b-form-row
              class="border-bottom-1 py-3 reorder-widgets"
              :class="{
                'keyboard-selected': keyboardSelectedWidget === widget.widgetID,
              }"
              :id="'reorder-widget-' + widget.widgetID"
              aria-describedby="widget-instructions"
              :aria-label="widget.widgetName + ' Widget'"
              :aria-grabbed="isKeyboardSelected"
              tabindex="0"
              role="application"
              v-for="(widget, index) in widgetsToReorder"
              :key="widget.widgetID"
              @keydown.self="handleWidgetUserInput($event, widget.widgetID)"
              @blur="focusWidgetId = -1"
              @focus="onFocusWidget(widget.widgetID)"
              @mouseover="focusWidgetType = focusTypeEnums.MouseOver"
            >
              <template v-if="focusWidgetType === focusTypeEnums.TabFocus && focusWidgetId === widget.widgetID">
                <div class="widget-hover-content">
                  <div>
                    <div class="d-none d-sm-block">
                      <div>Press spacebar to grab and set the widget. Use arrow keys to move the widget.</div>
                    </div>
                  </div>
                </div>
              </template>
              <b-col class="d-flex align-items-center" :class="{ 'opacity-40': widget.status.toLowerCase() === 'inactive' }">
                <div class="pr-3"><GrabHereIcon /></div>
                <div class="pr-3 widget-index">{{ index + 1 }}</div>
                <div class="preview-logo-wraper">
                  <div
                    class="preview-logo"
                    :style="{
                      backgroundColor: widget.widgetColor,
                    }"
                  >
                    <img :src="widget.widgetLogoBlobURI" />
                  </div>
                  <div class="pl-2 widget-name">
                    {{ widget.widgetName }} <span class="widget-status" v-if="widget.status.toLowerCase() === 'inactive'">({{ widget.status }})</span>
                  </div>
                </div>
              </b-col>
            </b-form-row>
          </transition-group>
        </draggable>
      </div>
      <b-form-row class="my-4">
        <b-col>
          <b-button type="button" id="save-reorder-widgets" v-activeBlur variant="primary" :disabled="saveLoadingIcon" @click="updateReorderWidgets()"
            >Save <b-spinner v-if="saveLoadingIcon" label="Spinning" small class="ml-2"></b-spinner
          ></b-button>
          <b-button type="button" id="cancel-reorder-widgets" v-activeBlur @click="$emit(`closeReorderWidgetsModal`)" class="ml-3" variant="outline-secondary"
            >Cancel</b-button
          >
        </b-col>
      </b-form-row>
    </div>
  </b-modal>
</template>
<script>
import { mapState } from 'vuex'
import draggable from 'vuedraggable'
import cloneDeep from 'lodash/cloneDeep'
import GrabHereIcon from '@/assets/svg/grab-here-icon.svg'
export default {
  name: 'ReorderWidgetsModal',
  components: {
    GrabHereIcon,
    draggable,
  },
  data() {
    return {
      modalShow: true,
      widgetsToReorder: [],
      focusWidgetType: null,
      isKeyboardSelected: false,
      keyboardSelectedWidget: null,
      isLocked: false,
      focusWidgetId: -1,
      focusTypeEnums: {
        MouseOver: 'MOUSEOVER',
        TabFocus: 'TABFOCUS',
      },
      saveLoadingIcon: false,
    }
  },
  computed: {
    ...mapState({
      widgets: (state) => state.widgets.widgets,
    }),
  },
  methods: {
    assignWidgtsList() {
      this.widgetsToReorder = cloneDeep(this.widgets)
      this.widgetsToReorder.sort((a, b) => a.widgetIndex - b.widgetIndex)
    },
    onFocusWidget(widgetId) {
      this.focusWidgetType = this.focusTypeEnums.TabFocus
      this.focusWidgetId = widgetId
    },
    handleWidgetUserInput(event, id) {
      if (this.isLocked === true) {
        event.preventDefault()

        if (event.code === 'Space') {
          this.isLocked = false
          this.isKeyboardSelected = false
          this.keyboardSelectedWidget = null
        } else if (event.code === 'ArrowLeft' || event.code === 'ArrowUp') {
          const hasMoved = this.moveWidget(id, -1)

          if (hasMoved) {
            setTimeout(() => {
              this.$nextTick(() => event.target.focus())
            })
          }
        } else if (event.code === 'ArrowDown' || event.code === 'ArrowRight') {
          const hasMoved = this.moveWidget(id, 1)
          if (hasMoved) {
            setTimeout(() => {
              this.$nextTick(() => event.target.focus())
            })
          }
        }
      } else {
        if (event.code === 'Space') {
          event.preventDefault()
          this.isLocked = true
          this.isKeyboardSelected = true
          this.keyboardSelectedWidget = id
        }
      }
    },
    onStartWidgetDrag(event) {
      this.isKeyboardSelected = true
      this.keyboardSelectedWidget = ~~event.item.id.substr(15)
    },
    onStopWidgetDrag(event) {
      this.isKeyboardSelected = false
      this.keyboardSelectedWidget = null
    },
    moveWidget(id, position) {
      const selectedWidget = id
      const collection = this.widgetsToReorder
      const index = collection.findIndex((i) => i.widgetID === selectedWidget)
      const item = collection.find((i) => i.widgetID === selectedWidget)
      if ((index === 0 && position === -1) || (index === collection.length - 1 && position === 1)) {
        return
      }

      collection.splice(index, 1)
      collection.splice(index + position, 0, item)

      return true
    },
    updateReorderWidgets() {
      this.saveLoadingIcon = true
      const payload = this.widgetsToReorder.map((widget, index) => {
        return { widgetId: widget.widgetID, widgetIndex: index + 1 }
      })
      this.$store
        .dispatch('widgets/reorderWidgets', payload)
        .then((res) => {
          this.saveLoadingIcon = false
          if (res.data && Object.hasOwn(res.data, 'errorMessage')) {
            this.$store.commit('common/setCustomToastData', {
              message: res.data.errorMessage,
              key: false,
              type: 'danger',
            })
          } else if (res.data) {
            this.$emit(`closeReorderWidgetsModal`)
            this.$store.dispatch('widgets/getWidgets')
            this.$store.commit('common/setCustomToastData', {
              message: false,
              key: 'WIDGET_REORDER_UPDATE_SUCCESS',
              type: 'success',
            })
          } else {
            this.$store.commit('common/setCustomToastData', {
              message: false,
              key: 'FAILED',
              type: 'danger',
            })
          }
        })
        .catch(() => {
          this.saveLoadingIcon = false
          this.$store.commit('common/setCustomToastData', {
            message: false,
            key: 'FAILED',
            type: 'danger',
          })
        })
    },
  },
  watch: {
    widgets: {
      handler() {
        this.assignWidgtsList()
      },
      immediate: true,
    },
  },
}
</script>
<style lang="scss" scoped>
.reorder-widgets {
  position: relative;
}
.reorder-widgets:hover {
  background-color: #ececec;
  cursor: grab;
}
.reorder-widgets.sortable-chosen {
  cursor: grabbing;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 8px #0000003d;
}
.widget-hover-content {
  color: #fff;
  padding: 0 3rem;
  top: 0;
  position: absolute;
  z-index: 15;
  text-align: center;
  background: #000000c4;
  height: 100%;
  cursor: grab;
  font-size: 14px;
  align-items: center;
  display: grid;
}
.keyboard-selected {
  border: 2px solid rgb(18 85 155);
  &:focus-visible {
    outline: 0;
  }
}
</style>
